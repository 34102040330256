export function numberGCodeFormat(n: number) {
    let formattedN;
    if (n){
        formattedN = Number(n).toFixed(2);
    } else {
        formattedN = "0.00";
    }
    return formattedN;
}

export function cmToMm(n:number) {
    return n * 10;
}


function monthStringFormat(numberMonth: number) {
    const months = ['янв.', 'фев.', 'мрт.', 'апр.', 'мая', 'июн.',
        'июл.', 'авг.', 'сен.', 'окт.', 'нбр.', 'дек.'];
    return months[numberMonth];
}

export function getDate(dateStr: string) {
    const date = new Date(dateStr);
    return `${date.getDate()} ${monthStringFormat(date.getMonth())} ${date.getFullYear()}`;
}

export function getDateTime(dateStr: string) {
    const date = new Date(dateStr);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    return `${getDate(dateStr)} ${hours}:${minutes}`
}

