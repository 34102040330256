export function getNowDateISO() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    let m;
    let d;
    if (dt < 10) {
        d = '0' + dt;
    }
    if (month < 10) {
        m = '0' + month;
    }

    return year + '-' + m + '-' + d
}

export function l_between_points(x1:number, y1:number, x2:number, y2:number) {
    return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
}

export function equal_2_p(a:number, b:number){
    return a.toFixed(2)===b.toFixed(2)
}
