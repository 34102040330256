import * as React from 'react';
import './Content.css'
import {Route, Switch} from "react-router";
import CeilingLayoutList from "./CeilingLayoutList/CeilingLayoutList";
import Layout from "./Layout/Layout";
import Settings from "./Settings/Settings";


class Content extends React.Component {


    render() {
        return (
            <Switch>
                <Route path='/layout/add' component={Layout}/>
                <Route path='/layout/:id' component={Layout}/>
                <Route path='/settings/' component={Settings}/>
                <Route path='/' component={CeilingLayoutList}/>
            </Switch>
        );
    }
}

export default Content;