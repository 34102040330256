import {BaseApi} from "../../../../services/api";
import {FAIL, LOAD_LAYOUTS_LIST, SET_FILTERS, START, SUCCESS} from "../../../../store/constants";
import history from "../../../../../history";
import {getUrlToLayout} from "../../utils/url";
import {closeModal, openModal} from "../../../../store/actions/modal";
import {LAYOUT_ADD_MODAL} from "../../../Layout/store/constants";

export function loadLayouts(page: number, filters: any, nowLoad: boolean) {
    const baseApi = new BaseApi();
    let update = (page === 1);
    if (nowLoad) {
        return (dispatch: any) => {

        }
    }
    return (dispatch: any) => {
        dispatch(loadStart());
        baseApi
            .get(`layout/` +
                getUrlToLayout(page,
                    filters.searchText,
                    filters.date,
                    filters.status))
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(loadSuccess(response.data, update));
                }
            })
            .catch((error: any) => {
                dispatch(loadFail(error.message));
            })
    }
}

export function loadStart() {
    return {
        type: LOAD_LAYOUTS_LIST + START
    }
}

export function loadSuccess(data: any, update: boolean) {
    return {
        type: LOAD_LAYOUTS_LIST + SUCCESS,
        payload: {data, update}
    }
}

export function loadFail(error: any) {
    return {
        type: LOAD_LAYOUTS_LIST + FAIL,
        payload: error
    }
}

export function openLayout(layout: any) {
    return (dispatch: any) => {
        history.push('/layout/' + layout.id);
    }
}

export function addLayout() {
    return (dispatch: any) => {
        history.push('/layout/add/');
        dispatch(openModal(LAYOUT_ADD_MODAL));
    }
}

export function closeAddLayout() {
    return (dispatch: any) => {
        history.push('/');
        dispatch(closeModal());
    }
}

export function setFilters(filters: any) {
    return (dispatch: any) => {
        dispatch({
            type: SET_FILTERS,
            payload: filters
        });
        dispatch(loadLayouts(1, filters, false))
    }

}

