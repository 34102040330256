import {USER_LOGIN_FAIL, USER_LOGIN_SUCCESS} from "../constants";

const initialState = {
    error: undefined,
    username: localStorage.getItem('username') || 'Not auth'
};

export default function user(state = initialState, action: any) {
    switch (action.type) {
        case (USER_LOGIN_FAIL): {
            return({
                error: action.payload,
                username: state.username
            });
        }
        case (USER_LOGIN_SUCCESS): {
            return({ ...state, username: action.payload});
        }
        default:
            return state;
    }
}