export const SET_LAYOUT_PARAMS = "SET_LAYOUT_PARAMS";
export const SET_LAYOUT_STATUS = "SET_LAYOUT_STATUS";

export const SAVE_LAYOUT = "SAVE_LAYOUT";
export const LOAD_LAYOUT = "LOAD_LAYOUT";

export const LAYOUT_ADD_MODAL = "LAYOUT_ADD_MODAL";
export const LAYOUT_EDIT_MODAL = "LAYOUT_EDIT_MODAL";
export const LAYOUT_SAVE_MODAL = "LAYOUT_SAVE_MODAL";
export const LAYOUT_G_CODE_OPTION_MODAL = "LAYOUT_G_CODE_OPTION_MODAL";
export const LAYOUT_PARAMS_MODAL = "LAYOUT_PARAMS_MODAL";