import React from "react";
import {getDateTime} from "../../../services/utils/format";
import {DONE_STATUS, NOT_DONE_STATUS, status} from "../../../services/utils/constants";


interface IProps {
    layoutParams: any;
    openEditModal: Function;
    setStatus: Function;
}

class LayoutParams extends React.Component<IProps> {

    openEditModal = () => {
        this.props.openEditModal();
    };

    setDoneStatus = () => {
        this.props.setStatus(DONE_STATUS);
    };

    setNotDoneStatus = () => {
        this.props.setStatus(NOT_DONE_STATUS);
    };

    getStatusButton(status: number) {
        if (status === NOT_DONE_STATUS) {
            return (
                <button className="btn btn-outline-success btn-block"
                        onClick={this.setDoneStatus}>Вырезано</button>
            )
        } else {
            return (
                <button className="btn btn-outline-danger btn-block"
                        onClick={this.setNotDoneStatus}>НЕ Вырезано</button>
            )
        }
    }

    render() {
        const {layoutParams} = this.props;
        return (
            <div className="layout-params-main container c-card">
                <div className="row">
                    <div className="col-12">
                        <h4>Параметры компоновки</h4>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-6">
                        <button className="btn btn-outline-dark btn-block"
                                onClick={this.openEditModal}>Изменить
                        </button>
                    </div>
                    <div className="col-md-6">
                        {this.getStatusButton(layoutParams.status)}
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-12">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th colSpan={2}>Параметры</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Наименование</td>
                                <td>{layoutParams.name}</td>
                            </tr>
                            <tr>
                                <td>Ширина полотна</td>
                                <td>{layoutParams.membrane.membraneWidth}</td>
                            </tr>
                            <tr>
                                <td>Дата сохранения</td>
                                <td>{layoutParams.createDate ? getDateTime(layoutParams.createDate) : '(Файл еще не сохранен)'}</td>
                            </tr>
                            <tr>
                                <td>Статус</td>
                                <td>{status[layoutParams.status]}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default LayoutParams;