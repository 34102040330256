import {CLOSE_MODAL, OPEN_MODAL} from "../constants";

export function openModal(modal: string) {
    return {
        type: OPEN_MODAL,
        modal: modal
    }
}

export function closeModal() {
    return {
        type: CLOSE_MODAL,
    }
}