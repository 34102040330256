import {CLOSE_MODAL, OPEN_MODAL} from "../constants";

const initialState = {
    open: false,
    type: undefined,
};

export default function modal(state = initialState, action: any) {
    switch (action.type) {
        case OPEN_MODAL: {
            return {
                open: true,
                type: action.modal
            };
        }
        case CLOSE_MODAL: {
            return {
                open: false,
                type: undefined
            };
        }
        default:
            return state;
    }
}