import * as React from 'react';
import {connect} from 'react-redux';

import './Auth.css'
import {authUser} from "../store/actions/user";
import history from '../../history';

interface IProps {
    authUser: Function;
    error: string;
}

interface IState {
    username: string;
    password: string;
}

class Auth extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props);
        this.state = {
            username: "",
            password: ""
        };
        if (localStorage.getItem('token')){
            history.push('/')
        }
    };

    loginButtonClickHandler = (event:any) => {
        event.preventDefault();
        this.props.authUser({
            username: this.state.username,
            password: this.state.password,
        });
    };

    loginInputChangeHandler = (event: any) => {
        this.setState({username: event.target.value})
    };

    passwordInputChangeHandler = (event: any) => {
        this.setState({password: event.target.value})
    };

    getError() {
        if (this.props.error) {
            return (
                <div className="user-login-error">
                    <h6>{this.props.error}</h6>
                </div>
            )
        }
    };


    render() {
        return (
            <div className="auth-main">
                <form className="form-signin">
                    <div className="text-center mb-4">
                        <img className="mb-4"
                             src="/logo.png"
                             alt=""
                             width="72"
                             height="72"/>
                        <h1 className="h3 mb-3 font-weight-normal">Nextf.Draw</h1>
                        <h4>Авторизация</h4>
                    </div>

                    <div className="form-label-group">
                        <input type="login"
                               onChange={this.loginInputChangeHandler}
                               id="inputLogin"
                               className="form-control"
                               placeholder="Логин"
                               required autoFocus/>
                    </div>

                    <div className="form-label-group">
                        <input type="password"
                               onChange={this.passwordInputChangeHandler}
                               id="inputPassword"
                               className="form-control"
                               placeholder="Пароль"
                               required/>
                    </div>

                    <button className="btn btn-lg btn-primary btn-block"
                            onClick={this.loginButtonClickHandler}>Войти</button>
                    {this.getError()}
                    <p className="mt-5 mb-3 text-muted text-center">2019 &copy; nextf.ru</p>
                </form>
            </div>
        );
    }
}

export default connect((state: any) => ({error: state.user.error}), {authUser})(Auth);