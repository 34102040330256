import {combineReducers} from 'redux';
import user from "./user";
import modal from "./modal";
import mainLayout from "../../Content/Layout/store/reducers/mainLayout";
import optionbar from "./optionbar";
import settings from "./settings";
import layouts from "../../Content/CeilingLayoutList/store/reducers/layouts";



export default combineReducers({
    user,
    modal,
    mainLayout,
    optionbar,
    settings,
    layouts
});