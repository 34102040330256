import {CLOSE_OPTIONBAR, OPEN_OPTIONBAR} from "../constants";

const initialState = {
    open: false,
    menu: undefined,
};

export default function optionbar(state = initialState, action: any) {
    switch (action.type) {
        case (OPEN_OPTIONBAR): {
            return ({
                open: true,
                menu: action.menu
            });
        }
        case (CLOSE_OPTIONBAR): {
            return ({
                open: false,
                menu: undefined
            });
        }
        default:
            return state;
    }
}