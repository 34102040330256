import * as React from 'react';
import './Menu.css'
import MenuItem from "./MenuItem/MenuItem";

class Menu extends React.Component {

    render() {
        return (
            <div className="menu-main pt-3">
                <ul className="p-0 m-0">
                    {/*<MenuItem*/}
                    {/*    text="Потолки"*/}
                    {/*    url='/'/>*/}
                    <MenuItem
                        text="Компоновки"
                        url='/'/>
                    <MenuItem
                        text="Настройки"
                        url='/settings'/>
                </ul>
            </div>
        );
    }
}

export default Menu;
