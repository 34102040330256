import {cmToMm, numberGCodeFormat} from "../utils/format";
import {Line} from "../../models/entities/line";

export function getGNum(point: number) {
    return numberGCodeFormat(cmToMm(point))
}

export function saveGCodeFile(name: string, gCodeStr: string) {
    let file = new Blob([gCodeStr], {type: "text/nc"});
    let a = document.createElement("a"),
        url = URL.createObjectURL(file);
    a.href = url;
    let date = new Date();
    a.download = name + ' ' + date.toLocaleDateString() + ".nc";
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
}

export function calcOffsetYInLine(line: Line, offset: number) {
    if (line.x1 === line.x2) {
        return offset;
    }
    return offset * ((line.y2 - line.y1) / (line.x2 - line.x1));
}