import {SAVE_SETTINGS_SUCCESS} from "../constants";
import {Settings} from "../../models/settings";
import history from "../../../history";


export function saveSettings(settings: Settings) {
    return (dispatch: any) => {
        try {
            localStorage.setItem('g_code_export_settings', JSON.stringify(settings));
            dispatch(saveSettingsSuccess(settings));
            history.push('/');
        } catch (e: any) {
            alert(e.stack);
        }
    }
}

export function saveSettingsSuccess(settings: Settings) {
    return {
        type: SAVE_SETTINGS_SUCCESS,
        payload: settings
    }
}
