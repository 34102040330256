import * as React from 'react';
import './LayoutGCodeOptionModal.css'
import ModalWindow from "../../../otherComponents/ModalWindow/ModalWindow";
import {connect} from "react-redux";
import {exportGCode} from "../store/actions/mainLayout";
import {Settings} from "../../../models/settings";

interface IProps {
    exportGCode: Function;
    layoutNotBlank: boolean;
    layoutEntities: any;
    membraneWidth: any;
    gSettings: Settings;
    layoutName: string;
}

interface IState {
    cuttingStart: number;
}

class LayoutGCodeOptionModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            cuttingStart: 2,
        }
    }

    exportGCodeClickHandler = (event: any) => {
        event.preventDefault();
        this.props.exportGCode(
            this.props.layoutName,
            {
                cuttingStart: (this.state.cuttingStart === 1),
                membraneWidth: this.props.membraneWidth,
            },
            this.props.gSettings,
            this.props.layoutEntities
        );
    };

    startCuttingSelectChangeHandler = (event: any) => {
        this.setState({
            cuttingStart: Number(event.target.value),
        });
    };

    render() {
        return (
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                        <h4>G-Code</h4>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-12">
                        <label className="optionbar-checkbox-label">Торцевание в начале</label>
                        <select value={this.state.cuttingStart}
                                onChange={this.startCuttingSelectChangeHandler}
                                className="custom-select">
                            <option value="1">Да</option>
                            <option value="2">Нет</option>
                        </select>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-12">
                        <button className="toolbar-button btn btn-outline-dark btn-sm"
                                onClick={this.exportGCodeClickHandler}>
                            <img src="/png/toolbar-generate.png" alt="icon"/>Сгенерировать
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalWindow(
    connect(
        (state: any) => ({
            layoutName: state.mainLayout.name,
            layoutNotBlank: state.mainLayout.notBlank,
            layoutEntities: state.mainLayout.entities,
            membraneWidth: state.mainLayout.membrane.membraneWidth,
            gSettings: state.settings.gCodeExportSettings
        }),
        {exportGCode})(LayoutGCodeOptionModal)
);