import {
    EXPORT_G_CODE_SUCCESS,
    IMPORT_DXF_TO_LAYOUT_SUCCESS,
    ROTATE_CEILING,
    SET_FIELD_WARNING, SUCCESS, FAIL
} from "../../../../store/constants";
import {LOAD_LAYOUT, SET_LAYOUT_PARAMS, SET_LAYOUT_STATUS} from "../constants";
import {NOT_DONE_STATUS} from "../../../../services/utils/constants";

const initialState = {
    name: null,
    creatDate: null,
    lastEditDate: null,
    status: NOT_DONE_STATUS,
    entities: [],
    notBlank: false,
    gCode: "",
    membrane: {
        membraneWidth: null
    },
    warnings: {
        loadWarning: null,
    },
};

export default function mainLayout(state = initialState, action: any) {
    switch (action.type) {
        case IMPORT_DXF_TO_LAYOUT_SUCCESS: {
            return {...state, entities: action.payload, notBlank: true};
        }
        case EXPORT_G_CODE_SUCCESS: {
            return {...state, gCode: action.payload};
        }
        case ROTATE_CEILING: {
            return {...state, entities: action.payload};
        }
        case LOAD_LAYOUT + SUCCESS: {
            const response = action.payload;
            return {...state,
                entities: JSON.parse(response.data),
                notBlank: true,
                name: response.name,
                creatDate: response.auto_date,
                status: response.status,
                membrane: {membraneWidth: response.membraneWidth}
            }
        }
        case LOAD_LAYOUT + FAIL: {
            return {...state, warnings: {...state.warnings, loadWarning: action.payload}}
        }
        case SET_FIELD_WARNING: {
            return {...state};
        }
        case SET_LAYOUT_PARAMS: {
            return {...state, membrane: {membraneWidth: action.payload.membraneWidth}, name: action.payload.name};
        }
        case SET_LAYOUT_STATUS + SUCCESS: {
            return {...state, status: action.payload};
        }
        default:
            return state;
    }
}