import * as React from 'react';
import './MenuItem.css'

interface IProps {
    text: string;
    url: string;
}

class MenuItem extends React.Component<IProps> {


    render() {
        const {text, url} = this.props;
        return (
                <a className="menu-item-main nav-link" href={url}>
                    <div className="row">
                        <div className="col-2">
                            <label className="circle"/>
                        </div>
                        <div className="col-10">
                            {text}
                        </div>
                    </div>
                </a>
        );
    }
}

export default MenuItem;