export function getUrlToLayout(page: number, searchText: string, date: string, status: number) {
    let url_prefix = '';
    let url = '';
    if (page) url += `page=${page}&`;
    if (searchText) url += `text=${searchText}&`;
    if (date) url += `date=${date}&`;
    if (status) {
        console.log(status);
        if (status !== 0){
            url += `status=${status}&`;
        }
    }
    if (url!== '') {
        url = '?' + url.slice(0, url.length - 1);
    }
    return url_prefix + url;
}