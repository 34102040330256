import React from 'react';
import './GCodeOptionView.css'
import { Entity } from "../../../models/entities/entity";
import { numberGCodeFormat } from "../../../services/utils/format";
import { Settings } from '../../../models/settings';
import { connect } from 'react-redux';
import { exportGCode } from '../store/actions/mainLayout';


interface IProps {
  exportGCode: Function;
  layoutNotBlank: boolean;
  layoutEntities: any;
  membraneWidth: any;
  gSettings: Settings;
  layoutName: string;
}

interface IState {
  cuttingStart: number;
  cuttingEnd: number;
  finalAction: number;
}

class GCodeOptionView extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      cuttingStart: 2,
      cuttingEnd: 2,
      finalAction: 1,
    }
  }

  exportGCodeClickHandler = (event: any) => {
    event.preventDefault();
    this.props.exportGCode(
      this.props.layoutName,
      {
        cuttingStart: (this.state.cuttingStart === 1),
        cuttingEnd: (this.state.cuttingEnd === 1),
        membraneWidth: this.props.membraneWidth,
        finalAction: this.state.finalAction,
      },
      this.props.gSettings,
      this.props.layoutEntities
    );
  };

  startCuttingSelectChangeHandler = (event: any) => {
    this.setState({
      cuttingStart: Number(event.target.value),
    });
  };

  endCuttingSelectChangeHandler = (event: any) => {
    this.setState({
      cuttingEnd: Number(event.target.value),
    });
  };

  finalActionSelectChangeHandler = (event: any) => {
    this.setState({
      finalAction: Number(event.target.value),
    });
  };

  // Pick<IState, keyof IState>

  selectChangeHandler = (key: keyof IState, value: number) => {
    this.setState({
      ...this.state,
      [key]: value
    });
  }

  render() {
    return (
      <div className="entity-view-main c-card">
        <div className="row">
          <div className="col-md-12">
            <h4>G-Code</h4>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <label className="optionbar-checkbox-label">Торцевание в Y = 0</label>
            <select value={this.state.cuttingStart}
                    onChange={this.startCuttingSelectChangeHandler}
                    className="custom-select">
              <option value="1">Да</option>
              <option value="2">Нет</option>
            </select>
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-12">
            <label className="optionbar-checkbox-label">Торцевание в Y = MAX</label>
            <select value={this.state.cuttingEnd}
                    onChange={this.endCuttingSelectChangeHandler}
                    className="custom-select">
              <option value="1">Да</option>
              <option value="2">Нет</option>
            </select>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <label className="optionbar-checkbox-label">Действие по окончанию реза</label>
            <select value={this.state.finalAction}
                    onChange={this.finalActionSelectChangeHandler}
                    className="custom-select">
              <option value="1">Переместить в X: 0 Y: 0</option>
              <option value="2">Переместить в X: 0 Y: Конец фигуры</option>
              <option value="3">Переместить в X: 0 Y: -600</option>
            </select>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <button className="toolbar-button btn btn-outline-dark btn-sm"
                    onClick={this.exportGCodeClickHandler}>
              <img src="/png/toolbar-generate.png" alt="icon" />Сгенерировать
            </button>
          </div>
        </div>

      </div>
    );
  }
}

export default connect(
        (state: any) => ({
            layoutName: state.mainLayout.name,
            layoutNotBlank: state.mainLayout.notBlank,
            layoutEntities: state.mainLayout.entities,
            membraneWidth: state.mainLayout.membrane.membraneWidth,
            gSettings: state.settings.gCodeExportSettings
        }),
        {exportGCode})(GCodeOptionView);
