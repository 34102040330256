import * as React from 'react';
import './Warning.css';

interface IProps {
    title: string;
    msg: string;
}

class Warning extends React.Component<IProps> {


    render() {
        const {title, msg} = this.props;
        return (
            <div className="alert alert-warning" role="alert">
                <h4 className="alert-heading">{title}</h4>
                <hr/>
                <p>{msg}</p>
            </div>
        )
    }
}

export default Warning;