import { gCodeParams } from "../../models/gCodeParams";
import { cmToMm, numberGCodeFormat } from "../utils/format";
import { Line } from "../../models/entities/line";
import { Settings } from "../../models/settings";
import { calcOffsetYInLine, getGNum } from "./utils";
import { sort } from '../utils/layout';
import { equal_2_p } from '../utils/utils';

// Main
export const END_PROGRAM = "M30";

// Instrument
export const LOWER_TOOL = "M3";
export const RAISE_TOOL = "M5";
export const DELAY = "G04 P0.5";

//Simple cut
export const SET_SIMPLE_CUT_IDLING = "G0 F100000";
export const SET_SIMPLE_CUT_LINE = "G1 F100000";

//Circular interpolation
export const C_INT_CLOCK = "G2";
export const C_INT_COUNTER_CLOCK = "G3";

//Base move command
export const GO_TO_START = "X0 Y0";

//BaseParams
export const OFFSET = 60;
export const MIN_LENGTH_FOR_OFFSET = 1.50;
export const MIN_LENGTH_FOR_OFFSET_RIGHT = 8;

export class GCodeGenerator {
  xNow: number;
  yNow: number;
  yMax: number; // Здесь храниться координата для торцевания в конце
  toolDown: boolean;
  gCodeStr: string;
  gCodeParams: gCodeParams;
  gSettings: Settings;


  constructor(gCodeParams: gCodeParams, gSettings: Settings) {
    this.xNow = 0;
    this.yNow = 0;
    this.yMax = 0; // Здесь храниться координата для торцевания в конце
    this.toolDown = false;
    this.gCodeStr = "";
    this.gCodeParams = gCodeParams;
    this.gSettings = gSettings;
  }

  setXYNow(x: number, y: number) {
    this.xNow = x;
    this.yNow = y;
    if (this.yMax < this.yNow) {
      this.yMax = this.yNow;
    }
  }

  getEntity(entity: any) {
    if (entity.type === 'LINE') {
      this.gCodeStr += this.getLine(entity);
    }
    if (entity.type === 'ARC') {
      this.gCodeStr += this.getArc(entity);
    }
    if (entity.type === 'CIRCLE') {
      this.gCodeStr += this.getCircle(entity);
    }
  }

  generateGCode(layoutEntities: any, finalAction: number) {

    this.gCodeStr += SET_SIMPLE_CUT_IDLING + '\n';
    this.gCodeStr += SET_SIMPLE_CUT_LINE + '\n';
    this.gCodeStr += this.goToStart();

    //Торцевание в начале по условию
    if (this.gCodeParams.cuttingStart) {
      this.gCodeStr += this.getCutting(this.gCodeParams, this.yNow);
    }

    let entities = layoutEntities;

    console.log('cuttingOptimize:', this.gSettings.cuttingOptimize);

    if (this.gSettings.cuttingOptimize !== 2) {
        entities = sort(layoutEntities)
    }

    console.log(entities);

    entities.map((entity: any, index: any) => {
      this.getEntity(entity);
    });

    if (this.gCodeParams.cuttingEnd) {
      // this.gCodeStr += this.goTo(this.xNow, this.yMax);
      this.gCodeStr += this.getCutting(this.gCodeParams, this.yMax);
    }

    // Переместить в X: 0 Y: 0 finalAction === 1
    if (finalAction === 1) {
      this.gCodeStr += this.goToStart();
    }

    // Переместить в X: 0 Y: Конец фигуры finalAction === 2
    if (finalAction === 2) {
      this.gCodeStr += this.goTo(0, this.yMax);
    }

    // Переместить в X: 0 Y: -600 finalAction === 3
    if (finalAction === 3) {
      this.gCodeStr += this.goTo(0, -60);
    }

    this.gCodeStr += END_PROGRAM + '\n';

    return this.gCodeStr;
  }

  getLine(line: any) {
    let gCodeStr = "";

    if (!line.cut) {
      return "";
    }

    if (getGNum(line.x1) === "0.00"
      && getGNum(line.x2) === "0.00") {
      return "";
    }

    if (numberGCodeFormat(cmToMm(line.y1)) === "0.00"
      && numberGCodeFormat(cmToMm(line.y2)) === "0.00") {
      return "";
    }

    // Если линяя слишком близко к левому или нижнему краю она не режится
    if ((line.x1 < MIN_LENGTH_FOR_OFFSET && line.x2 < MIN_LENGTH_FOR_OFFSET) ||
      (line.y1 < MIN_LENGTH_FOR_OFFSET && line.y2 < MIN_LENGTH_FOR_OFFSET)) {
      return "";
    }

    if ((line.x1 < MIN_LENGTH_FOR_OFFSET && line.y2 < MIN_LENGTH_FOR_OFFSET) ||
      (line.y1 < MIN_LENGTH_FOR_OFFSET && line.x2 < MIN_LENGTH_FOR_OFFSET)) {
      gCodeStr += this.goTo(line.x1, line.y1);
      gCodeStr += this.cutSimpleLine(line.x2, line.y2);

      return gCodeStr;
    }

    if (line.x1 < 8 || line.y1 < 8) {

      return this.getCutLineWithOffset(line);
    }

    // Тут все для реза справа ------------------------------------------------

    // // Если линяя слишком близко к правому краю полотна - она не режится
    // if (line.x1 > this.gCodeParams.membraneWidth - MIN_LENGTH_FOR_OFFSET_RIGHT &&
    //   line.x2 > this.gCodeParams.membraneWidth - MIN_LENGTH_FOR_OFFSET_RIGHT) {
    //     return "";
    // }

    // // Если линяя одним концом в запретной зоне с права - делаем отступ и режим
    // if (line.x1 > this.gCodeParams.membraneWidth - MIN_LENGTH_FOR_OFFSET_RIGHT) {
    //     return this.getCutLineWithOffset(line);
    // }

    //  ------------------------------------------------------------------------

    gCodeStr += this.goTo(line.x1, line.y1);
    gCodeStr += this.cutSimpleLine(line.x2, line.y2);

    return gCodeStr;
  }

  goToStart() {
    let gCodeStr = "";
    gCodeStr += this.raiseTool();
    gCodeStr += "G0" + "\n";
    gCodeStr += GO_TO_START + '\n';

    this.setXYNow(0.0, 0.0);
    return gCodeStr;
  }

  raiseTool() {
    if (!this.toolDown) {
      return "";
    }

    let gCodeStr = "";
    gCodeStr += RAISE_TOOL + '\n';
    gCodeStr += DELAY + '\n';

    this.toolDown = false;
    return gCodeStr;
  }

  lowerTool() {
    // Опускает интструмент
    if (this.toolDown) {
      return "";
    }
    this.toolDown = true;
    return LOWER_TOOL + '\n';
  }

  cutSimpleLine(x: number, y: number) {
    // Режет с текущей координаты до переданой и обновляет текущую координату
    let gCodeStr = "";
    gCodeStr += "G0" + "\n";
    gCodeStr += this.lowerTool();

    gCodeStr += "X" + numberGCodeFormat(cmToMm(x))
      + " " + "Y" + numberGCodeFormat(cmToMm(y)) + '\n';

    gCodeStr += "G0" + "\n";

    this.setXYNow(x, y);
    return gCodeStr;
  }

  goTo(x: number, y: number) {
    // Предвигает инструмент без реза и обновляет координату

    // Так как рассчет окружностей дает не иделаьно точные числа,
    // сравнивать приходится при помощи округления
    if (equal_2_p(this.xNow, x) && equal_2_p(this.yNow, y)) {
      return "";
    }

    let gCodeStr = "";
    gCodeStr += this.raiseTool();
    gCodeStr += "G0" + "\n";
    gCodeStr += "X" + numberGCodeFormat(cmToMm(x))
      + " " + "Y" + numberGCodeFormat(cmToMm(y)) + '\n';

    this.setXYNow(x, y);
    return gCodeStr;
  }

  getCutting(gCodeParams: gCodeParams, yNow: number) {
    // Торцевание. Просто проводит линюю по оси Y
    let gCodeStr = "";

    gCodeStr += this.goTo(60, yNow);
    gCodeStr += this.cutSimpleLine(0, yNow);
    gCodeStr += this.goTo(60, yNow);
    gCodeStr += this.cutSimpleLine(gCodeParams.membraneWidth, yNow);

    return gCodeStr;
  }

  getCutLineWithOffset(line: Line) {
    let gCodeStr = "";
    let xOffset;
    let yOffset;

    // gCodeStr += this.raiseTool();
    // gCodeStr += this.goTo(line.x1, line.y1);

    let short = false;

    if (line.x1 === line.x2) { // Проверяем вертиальную линюю
      // TODO Вот тут нужно проверять не четко вертикальную линюю, а наклоны ссаные
      if (line.y2 - line.y1 < OFFSET) {
        short = true;
      }
      const newOffset = Math.min(OFFSET, line.y2 - line.y1);
      xOffset = line.x1;
      yOffset = line.y1 + newOffset;
    } else {
      if (line.x2 - line.x1 < OFFSET) {
        short = true;
      }
      const newOffset = Math.min(OFFSET, line.x2 - line.x1);
      xOffset = line.x1 + newOffset;
      yOffset = line.y1 + calcOffsetYInLine(line, newOffset);
    }

    gCodeStr += this.goTo(xOffset, yOffset);
    gCodeStr += this.cutSimpleLine(line.x1, line.y1);
    if (!short) {
      gCodeStr += this.goTo(xOffset, yOffset);
      gCodeStr += this.cutSimpleLine(line.x2, line.y2);
    }

    return gCodeStr;
  }

  // ARC-------
  getArc(arc: any) {
    let r = arc.radius;
    // if (arc.angleLength < 0) r = -r;

    let cut_mode = 'G3';

    if (arc.fix_rotate) {
      cut_mode = 'G2';
    }// Костыль если мы поменяли направление реза дуги. Мнеяется в layout -> sort

    let gCodeStr = "";

    if (!arc.cut) {
      return "";
    }

    gCodeStr += this.goTo(arc.x1, arc.y1);


    gCodeStr += this.lowerTool();
    gCodeStr += cut_mode + ` X${numberGCodeFormat(cmToMm(arc.x2))} Y${numberGCodeFormat(cmToMm(arc.y2))} R${numberGCodeFormat(cmToMm(r))} \n`;

    this.setXYNow(arc.x2, arc.y2);
    // gCodeStr += this.raiseTool();
    return gCodeStr;
  }

  getCircle(e: any) {
    let r = e.radius;
    let cx = e.center.x;
    let cy = e.center.y;
    let start_point_x = cx + (r * Math.cos(0));
    let start_point_y = cy + (r * Math.sin(0));
    let end_point_x = cx + (r * Math.cos(3.14159));
    let end_point_y = cy + (r * Math.sin(3.14159));

    let cut_mode = 'G3';

    let gCodeStr = "";

    if (!e.cut) {
      return "";
    }
    gCodeStr += this.goTo(start_point_x, start_point_y);

    gCodeStr += this.lowerTool();
    gCodeStr += cut_mode + ` X${numberGCodeFormat(cmToMm(end_point_x))} Y${numberGCodeFormat(cmToMm(end_point_y))} R${numberGCodeFormat(cmToMm(r))} \n`;
    gCodeStr += cut_mode + ` X${numberGCodeFormat(cmToMm(start_point_x))} Y${numberGCodeFormat(cmToMm(start_point_y))} R${numberGCodeFormat(cmToMm(r))} \n`;
    // gCodeStr += this.raiseTool();

    this.setXYNow(end_point_x, end_point_y);
    this.yMax = cy + (r * Math.sin(1.5708))
    return gCodeStr;
  }
}





