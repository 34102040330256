import {
    FAIL,
    LOAD_LAYOUTS_LIST, SET_FILTERS,
    START,
    SUCCESS
} from "../../../../store/constants";
import {getNowDateISO} from "../../../../services/utils/utils";

const initialState = {
    layouts: Array(),
    load: false,
    page: 1,
    nextPage: null,
    hasMore: false,
    warning: null,
    filters: {
        status: 0,
        date: '',//getNowDateISO(),
        searchText: ''
    }
};

export default function layouts(state = initialState, action: any) {
    switch (action.type) {
        case LOAD_LAYOUTS_LIST + START: {
            return { ...state, load: true}
        }
        case LOAD_LAYOUTS_LIST + SUCCESS: {
            let {data, update} = action.payload;
            let hasMore = (data.next !== null);
            let layouts;
            let page;
            if (update) {
                layouts = data.results;
                page = 2;
            } else {
                layouts = state.layouts.concat(data.results);
                page = state.page + 1;
            }
            return { ...state,
                page: page,
                load: false,
                layouts: layouts,
                nextPage: action.payload.next,
                hasMore: hasMore
            }
        }
        case LOAD_LAYOUTS_LIST + FAIL: {
            return { ...state,
                load: false,
                hasMore: false,
                warning: action.payload};
        }
        case SET_FILTERS: {
            return { ...state, filters: action.payload};
        }
        default:
            return state;
    }
}
