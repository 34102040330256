import * as React from 'react';
import './layoutParamsEditModal.css'
import {connect} from "react-redux";
import ModalWindow from "../../../../otherComponents/ModalWindow/ModalWindow";

interface IProps {
}

interface IState {

}

class LayoutParamsEditModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }


    startCuttingSelectChangeHandler = (event: any) => {
        this.setState({
            cuttingStart: Number(event.target.value),
        });
    };

    render() {
        return (
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                        <h4>Параметры</h4>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-12">
                        <p>Наименование:</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <input type="text"
                               id="name"
                               className="form-control"/>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-12">
                        <button className="toolbar-button btn btn-outline-dark btn-sm">
                            <img src="/png/toolbar-save.png" alt="icon"/>Сохранить
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalWindow(
    connect(
        (state: any) => ({
            layoutNotBlank: state.mainLayout.notBlank,
            layoutEntities: state.mainLayout.entities,
            membraneWight: state.mainLayout.membrane.membraneWidth,
        }),
        {
        })(LayoutParamsEditModal)
);