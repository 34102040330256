import * as React from 'react';

import './CeilingLayoutTableRow.css';
import {getDateTime} from "../../../services/utils/format";
import {status} from "../../../services/utils/constants";

interface IProps {
    layout: any;
    openLayout: Function;
}

class CeilingLayoutTableRow extends React.Component<IProps> {

    clickHandler = (event: any) => {
        event.preventDefault();
        this.props.openLayout(this.props.layout);
    };


    render() {
        const {layout} = this.props;
        return (
            <tr className="ceiling-layout-table-row" onClick={this.clickHandler}>
                <td>{layout.id}</td>
                <td>{layout.name}</td>
                <td>{getDateTime(layout.auto_date)}</td>
                <td>{status[layout.status]}</td>
            </tr>
        );
    }
}

export default CeilingLayoutTableRow;