import * as React from 'react';
import './LayoutEditModal.css'
import ModalWindow from "../../../otherComponents/ModalWindow/ModalWindow";
import { easyCeiling, kompas, notDefined, odeon } from "../../../services/utils/unitConversion";
import {DONE_STATUS} from "../../../services/utils/constants";
import Warning from "../../../otherComponents/Warning/Warning";

interface IProps {
    save: Function;
    edit: boolean;
    name: string;
    membraneWidth: number;
    status: number;
}

interface IState {
    name: string,
    membraneWidth: number;
    source: string;
    dxfFile: any;
}

class LayoutEditModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            name: this.props.name || "",
            membraneWidth: this.props.membraneWidth || 500,
            source: kompas,
            dxfFile: undefined
        }
    }

    sourceSelectHandler = (event: any) => {
        this.setState({source: event.target.value});
    };

    fileSelectHandler = (event: any) => {
        const file = event.target.files[0]
        this.setState({dxfFile: file, name: file.name?.split('.')[0]});
    };

    nameChangeHandler = (event: any) => {
        this.setState({name: event.target.value});
    };

    membraneWightChangeHandler = (event: any) => {
        this.setState({membraneWidth: event.target.value});
    };

    dxfInputClickHandler = (event: any) => {
        if (this.state.name === "") {
            alert("Введите имя");
            return;
        }

        if (this.state.membraneWidth === 0) {
            alert("Введите ширину полотна");
            return;
        }

        if (!this.props.edit) {
            if (this.state.source === notDefined) {
                alert("Выберите программу из которой добавляется чертеж");
                return;
            }

            if (this.state.dxfFile === undefined) {
                alert("Выберите файл");
                return;
            }
        }
        this.props.save(this.state.dxfFile, this.state.source, this.state.name, this.state.membraneWidth, this.props.edit);
    };

    getStatusWarning(status: number) {
        if (status === DONE_STATUS) {
            return (
                <div>
                    <Warning title={'Эта компоновка уже вырезана!'}
                             msg={'Обратите внимание, что данная компоновка находится в статусе ВЫРЕЗАНО. ' +
                             'Убедитесь, что редактирование имеет смысл!'}/>
                </div>
            )
        }
    }

    getDxfFileBlock(edit: boolean) {
        if (edit) return;
        return(
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <h6>DXF</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <p>Программа в которой построен чертеж:</p>
                    </div>
                    <div className="col-md-3">
                        <select value={this.state.source}
                                onChange={this.sourceSelectHandler}
                                className="custom-select">
                            <option value={notDefined}>Не выбранно</option>
                            <option value={kompas}>Компас</option>
                            <option value={easyCeiling}>Easy Ceiling</option>
                            <option value={odeon}>Odeon</option>
                        </select>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-12">
                        <input type="file"
                               onChange={this.fileSelectHandler}/>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {status, edit} = this.props;
        return (
            <div className="modal-body">
                <div className="layout-params">
                    {this.getStatusWarning(status)}
                    <div className="row">
                        <div className="col-md-12">
                            <h6>Параметры</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Наименование</label>
                            <input type="text"
                                   className="form-control"
                                   value={this.state.name}
                                   onChange={this.nameChangeHandler}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Ширина полотна (см)</label>
                            <input type="number"
                                   className="form-control"
                                   value={this.state.membraneWidth || ''}
                                   onChange={this.membraneWightChangeHandler}/>
                        </div>
                    </div>
                    <hr/>
                </div>
                {this.getDxfFileBlock(edit)}
                <hr/>
                <div className="row">
                    <div className="col-md-12">
                        <button type="button"
                                onClick={this.dxfInputClickHandler}
                                className="btn btn-secondary btn-lg btn-block">
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalWindow(LayoutEditModal);
