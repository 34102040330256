import React from 'react';
import Navbar from "./Navbar/Navbar";
import './Layout.css'
import LayoutEditModal from "./LayoutEditModal/LayoutEditModal";
import { connect } from "react-redux";
import { closeModal, openModal } from "../../store/actions/modal";
import {
  SETTINGS_MENU
} from "../../store/constants";
import {
  createLayout,
  editLayout,
  loadLayout,
  rotateCeiling,
  saveLayout,
  setLayoutStatus
} from "./store/actions/mainLayout";
import { closeOptionBar, openOptionBar } from "../../store/actions/optionbar";
import LayoutSaveModal from "./LayoutSaveModal/LayoutSaveModal";
import LayoutGCodeOptionModal from "./LayoutGCodeOptionModal/LayoutGCodeOptionModal";
import LayoutParams from "./LayoutParams/LayoutParams";
import EntitiesGraphicView from "./EntitiesGraphicView/EntitiesGraphicView";
import LayoutParamsEditModal from "./LayoutParams/layoutParamsEditModal/layoutParamsEditModal";
import { closeAddLayout } from "../CeilingLayoutList/store/actions/layouts";
import {
  LAYOUT_ADD_MODAL,
  LAYOUT_EDIT_MODAL,
  LAYOUT_G_CODE_OPTION_MODAL,
  LAYOUT_PARAMS_MODAL,
  LAYOUT_SAVE_MODAL
} from "./store/constants";
import { NOT_DONE_STATUS } from "../../services/utils/constants";
import { Settings } from "../../models/settings";
import EntitiesView from './EntitiesView/EntitiesView';
import GCodeOptionView from './GCodeOptionView/GCodeOptionView';

interface IProps {
  openModal: Function,
  closeModal: Function,
  modalIsOpen: boolean,
  modalType: string,
  createLayout: Function,
  entities: Array<any>,
  openOptionBar: Function,
  optionBarMenu: string,
  optionBarIsOpen: boolean,
  closeOptionBar: Function,
  rotateCeiling: Function,
  gCode: string,
  layoutNotBlank: boolean,
  saveLayout: Function,
  warnings: any,
  match: any,
  loadLayout: Function,
  layoutName: string,
  layoutMembrane: any,
  layoutCreatDate: string,
  layoutStatus: number,
  closeAddLayout: Function,
  editLayout: Function,
  setLayoutStatus: Function,
  gSettings: Settings
}

class Layout extends React.Component<IProps> {

  componentDidMount() {
    const urlId = this.props.match.params.id;
    if (urlId) {
      this.props.loadLayout(urlId);
    }
  }

  layoutImportModalOpen = () => {
    this.props.openModal(LAYOUT_ADD_MODAL);
  };

  exportGCodeMenuOpen = () => {
    const urlId = this.props.match.params.id;
    if (!urlId) {
      alert('Для генерации G-CODE необходимо сначала сохранить файл!');
      return;
    }
    this.props.openModal(LAYOUT_G_CODE_OPTION_MODAL);
  };

  settingsMenuOpen = () => {
    this.props.openOptionBar(SETTINGS_MENU);
  };

  rotate = () => {
    if (this.props.layoutNotBlank) {
      this.props.rotateCeiling(this.props.entities);
    } else {
      alert("Файл не импортирован");
    }
  };

  saveLayoutModalOpen = () => {
    this.props.openModal(LAYOUT_SAVE_MODAL);
  };

  saveLayout = () => {
    const urlId = this.props.match.params.id;
    if (urlId) {
      this.props.editLayout(urlId, {
        name: this.props.layoutName,
        status: this.props.layoutStatus,
        membraneWidth: this.props.layoutMembrane.membraneWidth,
        data: JSON.stringify(this.props.entities),
      })
    } else {
      this.props.saveLayout({
        name: this.props.layoutName,
        status: this.props.layoutStatus,
        membraneWidth: this.props.layoutMembrane.membraneWidth,
        data: JSON.stringify(this.props.entities),
      })
    }

  };

  setStatus = (status: number) => {
    const urlId = this.props.match.params.id;
    if (!urlId) {
      alert('Для установки статуса необходимо сначала сохранить файл!');
      return;
    }
    this.props.setLayoutStatus(urlId, status)
  };


  getWarnings = () => {
    const { warnings } = this.props;
    if (warnings.loadWarning) {
      return (
        <div>
          <h4>Ошибка сети: {warnings.loadWarning}</h4>
        </div>
      );
    }
    return;
  };

  openEditParamsModal = () => {
    this.props.openModal(LAYOUT_EDIT_MODAL);
  };


  closeModal = () => {
    this.props.closeModal();
  };


  getModals() {
    const { modalType, modalIsOpen, createLayout } = this.props;
    if (!modalIsOpen) return;
    switch (modalType) {
      case LAYOUT_ADD_MODAL: {
        return (
          <LayoutEditModal save={createLayout}
                           close={this.props.closeAddLayout}
                           edit={false}
                           name={null}
                           membreaneWidth={null}
                           status={NOT_DONE_STATUS}
                           header="Новая компановка" />
        )
      }

      case LAYOUT_EDIT_MODAL: {
        return (
          <LayoutEditModal save={createLayout}
                           close={this.props.closeModal}
                           edit={true}
                           name={this.props.layoutName}
                           status={this.props.layoutStatus}
                           membreaneWidth={this.props.layoutMembrane.membraneWidth}
                           header="Редактирование компановки" />
        )
      }
      case LAYOUT_SAVE_MODAL: {
        return (
          <LayoutSaveModal save={this.saveLayout}
                           close={this.closeModal}
                           header="Сохранение" />
        )
      }
      case LAYOUT_G_CODE_OPTION_MODAL: {
        return (
          <LayoutGCodeOptionModal close={this.closeModal}
                                  header="G-Code" />
        )
      }
      case LAYOUT_PARAMS_MODAL: {
        return (
          <LayoutParamsEditModal close={this.closeModal}
                                 header="G-Code" />
        )
      }
      default:
        return;
    }
  }

  render() {
    const { layoutName, layoutCreatDate, layoutMembrane, layoutStatus, gSettings, entities } = this.props;
    const layoutParams = {
      name: layoutName,
      membrane: layoutMembrane,
      createDate: layoutCreatDate,
      status: layoutStatus
    };
    return (
      <div className="ceiling-layout-main">
        {this.getModals()}
        <Navbar layoutImportModalOpen={this.layoutImportModalOpen}
                exportGCodeMenuOpen={this.exportGCodeMenuOpen}
                rotate={this.rotate}
                saveLayout={this.saveLayoutModalOpen}
                settingsMenuOpen={this.settingsMenuOpen} />

        <div className="work-area">
          {this.getWarnings()}
          <div className="container work-container">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 entity-graphic-view">
                  <EntitiesGraphicView
                    entities={entities}
                    layoutParams={layoutParams}
                    gSettings={gSettings} />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <GCodeOptionView/>
                  <LayoutParams layoutParams={layoutParams}
                                openEditModal={this.openEditParamsModal}
                                setStatus={this.setStatus} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <EntitiesView entities={entities} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default connect(
  (state: any) => ({
    modalIsOpen: state.modal.open,
    modalType: state.modal.type,
    entities: state.mainLayout.entities,
    gCode: state.mainLayout.gCode,
    optionBarIsOpen: state.optionbar.open,
    optionBarMenu: state.optionbar.menu,
    layoutNotBlank: state.mainLayout.notBlank,
    warnings: state.mainLayout.warnings,
    layoutName: state.mainLayout.name,
    layoutMembrane: state.mainLayout.membrane,
    layoutCreatDate: state.mainLayout.creatDate,
    layoutStatus: state.mainLayout.status,
    gSettings: state.settings.gCodeExportSettings
  }),
  {
    openModal,
    closeModal,
    createLayout,
    closeOptionBar,
    rotateCeiling,
    openOptionBar,
    saveLayout,
    loadLayout,
    closeAddLayout,
    editLayout,
    setLayoutStatus
  }
)(Layout);
