import * as React from 'react';
import './UserCard.css'
import {connect} from "react-redux";
import {logout} from "../../store/actions/user";
import history from "../../../history";

interface IProps {
    isOpen: boolean;
    logout: Function;
    username: string;
}

class UserCard extends React.Component<IProps> {

    logoutButtonClickHandler = (event: any) => {
        event.preventDefault();
        this.props.logout();
    };

    settingsButtonClickHandler = (event: any) => {
        history.push('/settings');
    };

    getUserCard(isOpen: boolean) {
        if (isOpen) {
            return (
                <div className="user-card">
                    <div className="card-body">
                        <div className="row user-row">
                            <div className="user_image">
                                <img src="/png/user.png"
                                     width="32"
                                     height="32"
                                     alt="icon"/>
                            </div>
                            <div className="user_username">
                                {this.props.username}
                            </div>
                        </div>
                        <div className="row">
                            <button className="btn btn-sm btn-outline-primary"
                                    onClick={this.logoutButtonClickHandler}>Выход
                            </button>
                        </div>
                        <hr/>
                        <div className="row">
                            <button className="btn btn-outline-dark btn-sm"
                                    onClick={this.settingsButtonClickHandler}>
                                <img alt='icon' className="header-settings-img" src="/svg/settings.svg"/>Настройки
                            </button>
                        </div>
                    </div>
                </div>
            )
        }

    }

    render() {
        const {isOpen} = this.props;
        return (
            <div>
                {this.getUserCard(isOpen)}
            </div>
        )
    }
}

export default connect((state: any) => ({
    username: state.user.username
}), {logout})(UserCard);