export const AUTH_USER = "AUTH_USER";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const OPEN_DIALOG = "OPEN_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";

export const IMPORT_DXF_TO_LAYOUT_START = "IMPORT_DXF_TO_LAYOUT_START";
export const IMPORT_DXF_TO_LAYOUT_SUCCESS = "IMPORT_DXF_TO_LAYOUT_SUCCESS";
export const IMPORT_DXF_TO_LAYOUT_FAIL = "IMPORT_DXF_TO_LAYOUT_FAIL";

export const EXPORT_G_CODE_SUCCESS = "EXPORT_G_CODE_SUCCESS";
export const ROTATE_CEILING = "ROTATE_CEILING";


export const CLOSE_OPTIONBAR = "CLOSE_OPTIONBAR";
export const OPEN_OPTIONBAR = "OPEN_OPTIONBAR";


export const G_CODE_EXPORT_MENU = "G_CODE_EXPORT_MENU";
export const SETTINGS_MENU = "SETTINGS_MENU";

export const SAVE_SETTINGS_SUCCESS = "SAVE_SETTINGS_SUCCESS";

export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const LOAD_LAYOUTS_LIST = "LOAD_LAYOUTS_LIST";

export const SUCCESS = "_SUCCESS";
export const FAIL = "_FAIL";
export const START = "_START";

export const SET_FIELD_WARNING = "SET_FIELD_WARNING";
export const SET_MEMBRANE = "SET_MEMBRANE";




export const SET_FILTERS = "SET_FILTERS";
