import React from 'react';
import './EntitiesView.css'
import {Entity} from "../../../models/entities/entity";
import {numberGCodeFormat} from "../../../services/utils/format";


interface IProps {
    entities: Array<any>;
}

class EntitiesView extends React.Component<IProps> {

    getEntityName(entity: any) {
        if (entity.type === 'LINE') {
            return `Линия`
        } else {
            return;
        }
    }

    getEntityCutStatus(entity: any) {
        if (entity.cut) {
            return "Да";
        } else {
            return "Нет";
        }
    }

    entitiesMap(entities: Array<Entity>) {
        return (
            entities.map((entity: any, index: any) => {
                let x1 = numberGCodeFormat(entity.x1);
                let y1 = numberGCodeFormat(entity.y1);
                let x2 = numberGCodeFormat(entity.x2);
                let y2 = numberGCodeFormat(entity.y2);
                return (
                    <div key={index} className="">
                        <hr/>
                        {`${index + 1} ${this.getEntityName(entity)} (X${x1} см; Y${y1} см -> X${x2} см; Y${y2} см)`}
                        <div className="entity-status">Рез: {this.getEntityCutStatus(entity)}</div>
                    </div>);
            })
        );
    }

    render() {
        const {entities} = this.props;
        return (
            <div className="entity-view-main c-card">
                <h6>Фигуры</h6>
                {this.entitiesMap(entities)}
            </div>
        );
    }
}

export default EntitiesView;