import {CLOSE_OPTIONBAR, OPEN_OPTIONBAR} from "../constants";

export function openOptionBar(menu: string) {
    return {
        type: OPEN_OPTIONBAR,
        menu: menu
    }
}

export function closeOptionBar () {
    return {
        type: CLOSE_OPTIONBAR,
        menu: null
    }
}