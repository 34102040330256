import * as React from 'react';

import './CeilingLayoutList.css';
import CeilingLayoutTableRow from "./CeilingLayoutTableRow/CeilingLayoutTableRow";
import {connect} from "react-redux";
import history from "../../../history";
import {addLayout, loadLayouts, openLayout, setFilters} from "./store/actions/layouts";
import FilterBar from "./FilterBar/FilterBar";
import InfiniteScroll from 'react-infinite-scroller';
import Loader from "../../otherComponents/Loader/Loader";
import Warning from "../../otherComponents/Warning/Warning";

interface IProps {
    loadLayouts: Function;
    openLayout: Function;
    layouts: object;
    filters: any;
    page: number;
    load: boolean;
    warning: string;
    nextPage: string;
    setFilters: Function;
    hasMore: boolean;
    addLayout: Function;
}

class CeilingLayoutList extends React.Component<IProps> {

    componentDidMount() {
        this.props.loadLayouts(1, this.props.filters, false);
    }

    addLayoutButtonClickHandler = (event: any) => {
        event.preventDefault();
        this.props.addLayout();
    };

    openLayout = (layout: any) => {
        this.props.openLayout(layout)
    };

    loadMore = () => {
        const {page, filters, load} = this.props;
        this.props.loadLayouts(page, filters, load);
    };

    getLayoutsTable(layouts: any) {
        return (
            layouts.map((layout: any, index: any) => {
                return (<CeilingLayoutTableRow key = {layout.id}
                                               layout={layout}
                                               openLayout={this.openLayout}/>);
            })
        );
    };

    getLoader(load: boolean) {
        if (load) {
            return (<Loader/>)
        }
    }

    getWarning(warning: string) {
        if (warning !== null) {
            return (<Warning title={'Ошибка сети!'} msg={warning}/>)
        }
    }

    render() {
        const {load, filters, warning, setFilters, hasMore} = this.props;

        return (
            <div className="c-card container ceiling-layout-list">
                <div className="row">
                    <div className="col-md-9">
                        <h3>Компоновки</h3>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-outline-success float-right"
                                onClick={this.addLayoutButtonClickHandler}>+ Добавить
                        </button>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-12">
                        <FilterBar filters={filters} setFilters={setFilters}/>
                    </div>
                </div>
                {this.getWarning(warning)}
                <InfiniteScroll
                    pageStart={0}
                    useWindow={false}
                    loadMore={this.loadMore}
                    getScrollParent={()=>document.getElementById('scroll-container')}
                    hasMore={hasMore}>
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-hover table-bordered">
                                <thead>
                                <tr>
                                    <th scope="col">№</th>
                                    <th scope="col">Наименование</th>
                                    <th scope="col">Дата</th>
                                    <th scope="col">Статус</th>
                                </tr>
                                </thead>
                                <tbody>

                                {this.getLayoutsTable(this.props.layouts)}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </InfiniteScroll>
                {this.getLoader(load)}
            </div>
        );
    }
}

export default connect((state: any) => ({
    layouts: state.layouts.layouts,
    filters: state.layouts.filters,
    page: state.layouts.page,
    load: state.layouts.load,
    warning: state.layouts.warning,
    nextPage: state.layouts.nextPage,
    hasMore: state.layouts.hasMore
}), {
    loadLayouts,
    openLayout,
    setFilters,
    addLayout
})(CeilingLayoutList);
