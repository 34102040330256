import * as React from 'react';
import './Settings.css'
import { connect } from "react-redux";
import { saveSettings } from "../../store/actions/settings";

interface IProps {
  saveSettings: Function;
  gCodeExportSettings: any;
}

interface IState {
  workAreaWidth: number;
  workAreaLength: number;
  cuttingOptimize: number;
}

class Settings extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      workAreaWidth: this.props.gCodeExportSettings.workAreaWidth || 0,
      workAreaLength: this.props.gCodeExportSettings.workAreaLength || 0,
      cuttingOptimize: this.props.gCodeExportSettings.cuttingOptimize || 1,
    }
  }


  workAreaWidthChangeHandler = (event: any) => {
    this.setState({ workAreaWidth: event.target.value });
  };
  workAreaLengthChangeHandler = (event: any) => {
    this.setState({ workAreaLength: event.target.value });
  };
  cuttingOptimizeChangeHandler = (event: any) => {
    this.setState({ cuttingOptimize: Number(event.target.value) });
  };

  saveClickHandler = (event: any) => {
    this.props.saveSettings({
      workAreaWidth: this.state.workAreaWidth,
      workAreaLength: this.state.workAreaLength,
      cuttingOptimize: this.state.cuttingOptimize,
    });
  };

  render() {
    return (
      <div className="container settings-container">
        <div className="row">
          <div className="col-md-12">
            <h3>Настройки</h3>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <p>Длина стола (см):</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <input type="number"
                   id="table-len"
                   className="form-control"
                   value={this.state.workAreaLength}
                   onChange={this.workAreaLengthChangeHandler} />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <p>Ширина стола (см):</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <input type="number"
                   id="table-len"
                   className="form-control"
                   value={this.state.workAreaWidth}
                   onChange={this.workAreaWidthChangeHandler} />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <p>Оптимизация реза:</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <select className="custom-select"
                    value={this.state.cuttingOptimize}
                    onChange={this.cuttingOptimizeChangeHandler}>
              <option value="1">Включена</option>
              <option value="2">Выключена</option>
            </select>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <button type="button"
                    onClick={this.saveClickHandler}
                    className="btn btn-secondary btn-lg btn-block">
              Сохранить
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({ gCodeExportSettings: state.settings.gCodeExportSettings }),
  { saveSettings })(Settings);
