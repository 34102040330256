import {SAVE_SETTINGS_SUCCESS} from "../constants";

const initialState = {
    gCodeExportSettings: JSON.parse(localStorage.getItem('g_code_export_settings')||'{"settings":{"workAreaWidth":0, "workAreaLength":0, "cuttingOptimize":1}}')
};

export default function optionbar(state = initialState, action: any) {
    switch (action.type) {
        case (SAVE_SETTINGS_SUCCESS): {
            return {
                gCodeExportSettings: action.payload
            };
        }
        default:
            return state;
    }
}
