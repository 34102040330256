import * as React from 'react';
import "./HeaderUser.css"
import UserCard from "../UserCard/UserCard";

interface IState {
    userCardIsOpen: boolean;
}

interface IProps {
    username: string;
}

class HeaderUser extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            userCardIsOpen: false
        };
    }

    userButtonClickHandler = (event: any) => {
        event.preventDefault();
        this.setState({userCardIsOpen: !this.state.userCardIsOpen})
    };


    render() {
        return (
            <div className="header-user-block">
                <div className="row">
                    <div className="col-3">
                        <div className="user-avatar">
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="user"
                             onClick={this.userButtonClickHandler}>
                            {this.props.username}</div>
                        <UserCard isOpen={this.state.userCardIsOpen}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeaderUser;