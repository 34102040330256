import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import * as serviceWorker from './serviceWorker.js';
import 'bootstrap/dist/css/bootstrap.css';
import {Provider} from 'react-redux'
import {applyMiddleware, createStore} from 'redux'
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import reducer from './App/store/reducers'
import {Router} from 'react-router';
import history from './history';


const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
ReactDOM.render((
        <Provider store={store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>),
    document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
