import axios from 'axios';
import {displayError} from "./utils/exeptions";

export const baseUrl = 'https://draw.nextf.ru/api/';
// export const baseUrl = process.env.API_URL;

// export const baseUrl = 'http://127.0.0.1:8000/api/';

export class BaseApi {
    baseUrl = baseUrl;
    headers = {'Authorization': 'token ' + this.getToken()};

    getUrl(url:string) {
        return this.baseUrl + url;
    }

    post(url:string, data:object) {
        return axios
            .post(this.getUrl(url), data, {
                headers: this.headers
            })
            .catch(error => {
                displayError(error.message, 'SERVER')
            })
    }

    get(url:string) {
        return axios
            .get(this.getUrl(url), {
                headers: this.headers
            });
    }

    put(url:string, data:object) {
        return axios
            .put(this.getUrl(url), data, {
                headers: this.headers
            });
    }

    deleteRequest(url:string) {
        return axios
            .delete(this.getUrl(url), {
                headers: this.headers
            });
    }

    getToken() {
        return localStorage.getItem('token');
    }
}
