import React from 'react';
import './Main.css';
import Header from "../Header/Header";
import Content from "../Content/Content";
import {connect} from "react-redux";
import Menu from "../Menu/Menu";

interface IProps {
}

class Main extends React.Component<IProps> {

    render() {
        return (
            <div>
                <Header/>
                <div className="main">
                    <div className="row p-0 m-0">
                        <div className="col-md-2 p-0 m-0">
                            <Menu/>
                        </div>
                        <div id="scroll-container" className="col-md-10 content-main p-0">
                            <Content/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: any) => ({
}), {
})(Main);
