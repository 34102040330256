import * as React from 'react';
import './Loader.css';

interface IProps {

}

class Loader extends React.Component<IProps> {


    render() {
        return (
            <div className='container'>
                Loading...
            </div>
        )
    }
}

export default Loader;