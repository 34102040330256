import React, {Component} from 'react';
import './App.css';
import Main from "./Main/Main";
import {Switch, Route, Redirect} from "react-router";
import Auth from "./Auth/Auth";

interface PrivateRouteProps {
    path: string,
    component: any
}

const PrivateRoute = (props: PrivateRouteProps) => {
    let page;
    !localStorage.getItem('token')
        ? page = <Redirect to='/auth'/>
        : page = <Route path='' component={Main}/>;
    return page;
};


class App extends React.Component {

    render() {
        return (
            <Switch>
                <Route path='/auth' component={Auth}/>
                <PrivateRoute path='' component={Main}/>
            </Switch>
        );
    }
}

export default App;
