import React from 'react';
import './EntitiesGraphicView.css'
import { Entity } from "../../../models/entities/entity";


interface IProps {
  entities: Array<any>;
  layoutParams: any;
  gSettings: any;
}

class EntitiesGraphicView extends React.Component<IProps> {


  lineClickHandler = () => {
    alert("lineClickHandler");
  };

  getOffset = (point: number) => {
    return point + 10
  };

  getEntityStroke(entity: Entity) {
    if (entity.cut) {
      return "black";
    } else {
      return "lightgray";
    }
  };

  entitiesGraphicMap(entities: any) {
    return (
      entities.map((entity: any, index: any) => {
        if (entity.type === "LINE") {
          return (
            <line key={index}
                  onClick={this.lineClickHandler}
                  x1={this.getOffset(entity.x1)}
                  y1={this.getOffset(entity.y1)}
                  x2={this.getOffset(entity.x2)}
                  y2={this.getOffset(entity.y2)}
                  stroke={this.getEntityStroke(entity)}
                  strokeWidth="3" />
          );
        }
        if (entity.type === "ARC") {
          console.log(entity);

          let cx = this.getOffset(entity.center.x);
          let cy = this.getOffset(entity.center.y);
          let r = entity.radius;
          let startAngle = entity.startAngle;
          let endAngle = entity.endAngle;

          let x_axis_rotation = '0'; // Поворот элипса! Видимо работает для элипсов
          let large_arc_flag = '0'; // Должна ли дуга быть больше или меньше 180 градусов
          let sweep_flag = '1'; // Должна дуга двигаться по отрицательным углам или по положительным

          // if (entity.angleLength < 0) large_arc_flag = '0';

          let start_point = `${cx + (r * Math.cos(startAngle))} ${cy + (r * Math.sin(startAngle))}`;
          let end_point = `${cx + (r * Math.cos(endAngle))} ${cy + (r * Math.sin(endAngle))}`;

          let d = `M ${start_point}
                    A ${r} ${r} 
                    ${x_axis_rotation} 
                    ${large_arc_flag} 
                    ${sweep_flag} 
                    ${end_point}`;
          console.log(large_arc_flag)
          return (
            <path key={index}
                  d={d}
                  fill='none'
                  stroke={this.getEntityStroke(entity)}
                  strokeWidth="3" />
          );
        }
        if (entity.type === "CIRCLE") {
          let cx = this.getOffset(entity.center.x);
          let cy = this.getOffset(entity.center.y);
          let r = entity.radius;

          return (
            <circle key={index}
                    cx={cx}
                    cy={cy}
                    r={r}
                    fill='none'
                    stroke={this.getEntityStroke(entity)}
                    strokeWidth="3" />
          );
        }
        if (entity.type === "SPLINE") {
          return (
            entity.controlPoints.map((point: any, indexCP: any) => {
              if (entity.controlPoints[indexCP + 1] !== undefined) {
                return <line key={indexCP + index}
                             x1={this.getOffset(point.x)}
                             y1={this.getOffset(point.y)}
                             x2={this.getOffset(entity.controlPoints[indexCP + 1].x)}
                             y2={this.getOffset(entity.controlPoints[indexCP + 1].y)}
                             stroke="red"
                             strokeWidth="3" />
              }
            })
          );
        }
      })
    );
  }

  render() {
    const { entities, layoutParams, gSettings } = this.props;

    const membraneWidth = layoutParams?.membrane?.membraneWidth;
    const tableWidth = Number(gSettings?.workAreaWidth);
    const tableLength = Number(gSettings?.workAreaLength);

    // console.log('GW', gSettings, membraneWidth, tableWidth, tableLength)
    return (
      <div className="entity-graphic-view-main c-card">
        <h4>Чертеж</h4>
        <svg width={tableWidth + 20} height={tableLength + 20}
             viewBox={`0 0 ${tableWidth + 20} ${tableLength + 20}`}
             className="entity-view-svg"
             xmlns="http://www.w3.org/2000/svg">
          {this.entitiesGraphicMap(entities)}

          <line x1={this.getOffset(0)}
                y1={this.getOffset(0)}
                x2={this.getOffset(0)}
                y2={this.getOffset(tableLength)}
                stroke={'lightgray'}
                strokeWidth="3" />
          <line x1={this.getOffset(0)}
                y1={this.getOffset(0)}
                x2={this.getOffset(tableWidth)}
                y2={this.getOffset(0)}
                stroke={'lightgray'}
                strokeWidth="3" />
          <line x1={this.getOffset(tableWidth)}
                y1={this.getOffset(0)}
                x2={this.getOffset(tableWidth)}
                y2={this.getOffset(tableLength)}
                stroke={'lightgray'}
                strokeWidth="3" />
          <line x1={this.getOffset(0)}
                y1={this.getOffset(tableLength)}
                x2={this.getOffset(tableWidth)}
                y2={this.getOffset(tableLength)}
                stroke={'lightgray'}
                strokeWidth="3" />

          <line x1={this.getOffset(membraneWidth)}
                y1={this.getOffset(0)}
                x2={this.getOffset(membraneWidth)}
                y2={this.getOffset(900)}
                stroke={'red'}
                strokeWidth="3" />
        </svg>
      </div>
    );
  }
}

export default EntitiesGraphicView;
