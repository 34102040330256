import {baseUrl} from "../../services/api";
import axios from 'axios';
import {USER_LOGIN_FAIL, USER_LOGIN_SUCCESS, USER_LOGOUT_SUCCESS} from "../constants";
import history from "../../../history";

export function authUser(user: Object) {
    return (dispatch: any) => {
        axios
            .post(baseUrl + `login/`, user)
            .then(response => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('username', response.data.username);
                history.push('/');
                dispatch(loginSuccess(response.data.username));
            })
            .catch((error: any) => {
                dispatch(loginFail(error.message));
            })
    }
}

export function logout() {
    return (dispatch: any) => {
        localStorage.removeItem('token');
        history.push('/auth');
        dispatch(logoutSuccess())
    }
}

export function logoutSuccess() {
    return{
        type: USER_LOGOUT_SUCCESS
    }
}

export function loginSuccess(username: string) {
    return {
        type: USER_LOGIN_SUCCESS,
        payload: username
    }
}

export function loginFail(error: String) {
    return {
        type: USER_LOGIN_FAIL,
        payload: error
    }
}
