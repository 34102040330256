import {
    EXPORT_G_CODE_SUCCESS, FAIL,
    IMPORT_DXF_TO_LAYOUT_FAIL,
    IMPORT_DXF_TO_LAYOUT_START,
    IMPORT_DXF_TO_LAYOUT_SUCCESS, ROTATE_CEILING, START, SUCCESS
} from "../../../../store/constants";
import DxfParser from "dxf-parser";
import {closeModal} from "../../../../store/actions/modal";
import {gCodeParams} from "../../../../models/gCodeParams";
import {GCodeGenerator} from "../../../../services/gCode/gCodeGenerator";
import {mapDxfToEntity, reverse, rotate} from "../../../../services/utils/layout";
import {Layout} from "../../../../models/layout";
import {BaseApi} from "../../../../services/api";
import history from "../../../../../history";
import {LOAD_LAYOUT, SAVE_LAYOUT, SET_LAYOUT_PARAMS, SET_LAYOUT_STATUS} from "../constants";
import {Settings} from "../../../../models/settings";
import {easyCeiling} from "../../../../services/utils/unitConversion";
import {NOT_DONE_STATUS} from "../../../../services/utils/constants";
import {saveGCodeFile} from "../../../../services/gCode/utils";


export function createLayout(file: any, source: string, name: string, membraneWidth: number, edit: boolean) {
    return (dispatch: any) => {
        dispatch(setLayoutParams(name, membraneWidth));
        dispatch(setLayoutStatusSuccess(NOT_DONE_STATUS));

        if (!edit) {
            dispatch(importDxfStart());
            const reader = new FileReader();
            reader.onload = () => {
                let parser = new DxfParser();
                try {
                    let dxfObject = parser.parseSync(reader.result);
                    let entities = mapDxfToEntity(dxfObject, source);
                    // if (source === easyCeiling) {
                    //     entities = reverse(entities);
                    //     entities = rotate(entities);
                    // }
                    dispatch(importDxfSuccess(entities));
                    dispatch(closeModal());
                } catch (err:any) {
                    dispatch(importDxfFail(err));
                    return alert('Ошибка импорта ' + err.stack);
                }
            };
            reader.readAsText(file);
        }

        dispatch(closeModal());
    }
}

export function importDxfSuccess(entities: any) {
    return {
        type: IMPORT_DXF_TO_LAYOUT_SUCCESS,
        payload: entities
    }
}

export function importDxfStart() {
    return {
        type: IMPORT_DXF_TO_LAYOUT_START,
    }
}

export function importDxfFail(error: any) {
    return {
        type: IMPORT_DXF_TO_LAYOUT_FAIL,
    }
}

export function exportGCode(layoutName: string,
                            params: gCodeParams,
                            gSettings: Settings,
                            layoutEntities: any) {

    return (dispatch: any) => {
        if (!layoutEntities || layoutEntities.length === 0) {
            alert('Не загружен чертеж');
            return;
        }
        if (params.membraneWidth === 0) {
            alert('Не указана ширина полотна')
        }
        let gCodeGenerator = new GCodeGenerator(params, gSettings);
        let gCode = gCodeGenerator.generateGCode(layoutEntities, params.finalAction);
        saveGCodeFile(layoutName, gCode);
        dispatch(exportGCodeSuccess(gCode));
    }
}

export function exportGCodeSuccess(gCode: string) {
    return {
        type: EXPORT_G_CODE_SUCCESS,
        payload: gCode
    }
}

export function rotateCeiling(entities: Array<any>) {
    return {
        type: ROTATE_CEILING,
        payload: rotate(entities)
    }
}

export function saveLayout(layout: Layout) {
    const baseApi = new BaseApi();
    return (dispatch: any) => {
        dispatch(saveStart());
        baseApi
            .post(`layout/`, layout)
            .then((response: any) => {
                history.push('/');
                dispatch(saveSuccess(response.data));
                dispatch(closeModal());
            })
            .catch((error) => {
                dispatch(saveFail(error.message));
            })
    }
}

export function editLayout(id: number, layout: Layout) {

    const baseApi = new BaseApi();
    return (dispatch: any) => {
        dispatch(saveStart());
        baseApi
            .put(`layout/${id}/`, layout)
            .then((response: any) => {
                dispatch(saveSuccess(response.data));
                dispatch(closeModal());
            })
            .catch((error) => {
                dispatch(saveFail(error.message));
            })
    }
}

export function saveStart() {
    return {
        type: SAVE_LAYOUT + START,
    }
}

export function saveSuccess(data: any) {
    return {
        type: ROTATE_CEILING + SUCCESS,
    }
}

export function saveFail(errorMessage: any) {
    return {
        type: ROTATE_CEILING + FAIL,
    }
}

export function loadLayoutStart() {
    return {
        type: LOAD_LAYOUT + START,
    }
}

export function loadLayoutSuccess(layout: any) {
    return {
        type: LOAD_LAYOUT + SUCCESS,
        payload: layout
    }
}

export function loadLayoutFail(errorMsg: string) {
    return {
        type: LOAD_LAYOUT + FAIL,
        payload: errorMsg
    }
}

export function loadLayout(id: number) {
    const baseApi = new BaseApi();
    return (dispatch: any) => {
        dispatch(loadLayoutStart());
        baseApi
            .get(`layout/${id}/`)
            .then((response: any) => {
                dispatch(loadLayoutSuccess(response.data));
            })
            .catch((error) => {
                dispatch(loadLayoutFail(error.message));
            })
    }
}

export function setLayoutParams(name: string, membraneWidth: number) {
    return {
        type: SET_LAYOUT_PARAMS,
        payload: {name, membraneWidth}
    }
}

export function setLayoutStatus(id: number, status: number) {
    const baseApi = new BaseApi();
    return (dispatch: any) => {
        dispatch(loadLayoutStart());
        baseApi
            .post(`layout/${id}/status/`, {status: status})
            .then((response: any) => {
                dispatch(setLayoutStatusSuccess(response.data));
                dispatch(loadLayout(id));
            })
            .catch(() => {
                dispatch(setLayoutStatusFail());
            })
    }
}

export function setLayoutStatusSuccess(status: number) {
    return {
        type: SET_LAYOUT_STATUS + SUCCESS,
        payload: status
    }
}

export function setLayoutStatusFail() {
    alert('Ошибка установки статуса!');
    return {
        type: SET_LAYOUT_STATUS + FAIL,
    }
}


