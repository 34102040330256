import * as React from 'react';
import './LayoutSaveModal.css'
import ModalWindow from "../../../otherComponents/ModalWindow/ModalWindow";

interface IProps {
    save: Function
}



class LayoutSaveModal extends React.Component<IProps> {


    saveClickHandler = (event: any) => {
        this.props.save();
    };

    render() {
        return (
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                        <h4>Сохранение компоновки</h4>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-12">
                        <button type="button"
                                onClick={this.saveClickHandler}
                                className="btn btn-secondary btn-lg btn-block">
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalWindow(LayoutSaveModal);