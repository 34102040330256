import * as React from 'react';
import './Toolbar.css'
import history from "../../../../history";

interface IProps {
    layoutImportModalOpen: Function;
    exportGCodeMenuOpen: Function;
    settingsMenuOpen: Function;
    rotate: Function;
    saveLayout: Function;
}

class Toolbar extends React.Component<IProps> {

    importClickHandler = (event: any) => {
        event.preventDefault();
        this.props.layoutImportModalOpen();
    };

    gCodeClickHandler = (event: any) => {
        event.preventDefault();
        this.props.exportGCodeMenuOpen();
    };

    settingsClickHandler = (event: any) => {
        event.preventDefault();
        this.props.settingsMenuOpen();
    };

    rotateClickHandler = (event: any) => {
        event.preventDefault();
        this.props.rotate();
    };

    saveClickHandler = (event: any) => {
        event.preventDefault();
        this.props.saveLayout();
    };

    backClickHandler = (event: any) => {
        history.push('/');
    }

    render() {
        return (
            <div className="toolbar-main">
                <div className="row">
                    <div className="col-12">
                        <button className="toolbar-button btn btn-outline-dark"
                                onClick={this.backClickHandler}>
                            <img src="/png/toolbar-menu.png"/>К списку
                        </button>
                        {/*<button className="toolbar-button btn btn-outline-dark btn-sm"*/}
                        {/*        onClick={this.importClickHandler}>*/}
                        {/*    <img src="/png/toolbar-import.png" alt="icon"/>Импорт*/}
                        {/*</button>*/}
                        <button className="toolbar-button btn btn-outline-dark"
                                onClick={this.saveClickHandler}>
                            <img src="/png/toolbar-save.png"/>Сохранить
                        </button>
                        {/*<button className="toolbar-button btn btn-outline-dark"*/}
                        {/*        onClick={this.gCodeClickHandler}>*/}
                        {/*    <img src="/png/toolbar-generate.png" alt="icon"/>g-code*/}
                        {/*</button>*/}
                        {/*<button className="toolbar-button btn btn-outline-dark btn-sm"*/}
                        {/*        onClick={this.settingsClickHandler}>*/}
                        {/*    <img src="/png/toolbar-generate.png" alt="icon"/>Настройки*/}
                        {/*</button>*/}
                        {/*<button className="toolbar-button btn btn-outline-dark btn-sm"*/}
                        {/*        onClick={this.rotateClickHandler}>*/}
                        {/*    <img src="/png/toolbar-rotate.png" alt="icon"/>Повернуть*/}
                        {/*</button>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default Toolbar;