import * as React from 'react';
import Toolbar from "../Toolbar/Toolbar";
import './Navbar.css';

interface IProps {
    layoutImportModalOpen: Function;
    exportGCodeMenuOpen: Function;
    settingsMenuOpen: Function;
    rotate: Function;
    saveLayout: Function;
}

class Navbar extends React.Component<IProps> {

    render() {
        return (
            <div className="navbar-main">
                <div className="row">
                    <div className="col-12">
                        {/*<div>{"<-"} Назад</div>*/}
                        <Toolbar layoutImportModalOpen={this.props.layoutImportModalOpen}
                                 exportGCodeMenuOpen={this.props.exportGCodeMenuOpen}
                                 rotate={this.props.rotate}
                                 saveLayout={this.props.saveLayout}
                                 settingsMenuOpen={this.props.settingsMenuOpen}/>
                    </div>

                </div>
            </div>
        );
    }
}

export default Navbar;