import * as React from 'react';
import './FilterBar.css';


interface IProps {
    filters: any;
    setFilters: Function;
}


class FilterBar extends React.Component<IProps> {


    searchChangeHandler = (event: any) => {
        const filters = this.props.filters;
        event.preventDefault();
        this.props.setFilters({
            ...filters,
            searchText: event.target.value
        })
    };

    dateChangeHandler = (event: any) => {
        const filters = this.props.filters;
        event.preventDefault();
        this.props.setFilters({
            ...filters,
            date: event.target.value
        })
    };

    statusChangeHandler = (event: any) => {
        const filters = this.props.filters;
        event.preventDefault();
        this.props.setFilters({
            ...filters,
            status: event.target.value
        })
    };


    render() {
        const {filters} = this.props;
        return (
            <div>
                <div className='row'>
                    <div className='col-md-4'>
                        <label>Поиск</label>
                        <input type='text'
                               value={filters.searchText}
                               onChange={this.searchChangeHandler}
                               className='form-control'/>
                    </div>
                    <div className='col-md-4'>
                        <label>Дата</label>
                        <input type='date'
                               value={filters.date}
                               onChange={this.dateChangeHandler}
                               className='form-control'/>
                    </div>
                    <div className='col-md-4'>
                        <label>Статус</label>
                        <select value={filters.status}
                                onChange={this.statusChangeHandler}
                                className="custom-select">
                            <option value="0">Все</option>
                            <option value="1">Не вырезано</option>
                            <option value="2">Вырезано</option>
                        </select>
                    </div>
                </div>
                <hr/>
            </div>
        );
    }
}

export default FilterBar;