import { equal_2_p, l_between_points } from './utils';
import { easyCeiling, odeon } from './unitConversion';

export function mapDxfToEntity(dxfObject: any, source: string) {
  let entities: any = [];
  console.log(dxfObject, source);
  dxfObject.entities.map((entity: any, index: number) => {
    if (entity.type === "LINE") {
      entities.push({
        type: entity.type,
        cut: true,
        x1: entity.vertices[0].x,
        y1: entity.vertices[0].y,
        x2: entity.vertices[1].x,
        y2: entity.vertices[1].y
      });
    }
    // if (entity.type === "SPLINE") {
    //     entities.push({
    //         type: entity.type,
    //         cut: true,
    //         controlPoints: entity.controlPoints
    //     });
    // }
    if (entity.type === "ARC") {
      let cx = entity.center.x;
      let cy = entity.center.y;
      let r = entity.radius;
      entities.push({
        type: entity.type,
        cut: true,
        center: entity.center,
        radius: r,
        startAngle: entity.startAngle,
        endAngle: entity.endAngle,
        angleLength: entity.angleLength,
        x1: cx + (r * Math.cos(entity.startAngle)),
        y1: cy + (r * Math.sin(entity.startAngle)),
        x2: cx + (r * Math.cos(entity.endAngle)),
        y2: cy + (r * Math.sin(entity.endAngle)),
      });
    }
    if (entity.type === "CIRCLE") {
      let cx = entity.center.x;
      let cy = entity.center.y;
      let r = entity.radius;
      entities.push({
        type: entity.type,
        cut: true,
        center: entity.center,
        radius: r,
        x1: cx + (r * Math.cos(0)),
        y1: cy + (r * Math.sin(0)),
        x2: cx + (r * Math.cos(0)),
        y2: cy + (r * Math.sin(0)),
      });
    }
    if (entity.type === 'LWPOLYLINE') {
      for (let i = 0; i < entity.vertices.length; i++) {
        // На последнем шаге замыкаем многоугольник
        if (i === entity.vertices.length - 1) {
          entities.push({
            type: "LINE",
            cut: true,
            x1: entity.vertices[i].x,
            y1: entity.vertices[i].y,
            x2: entity.vertices[0].x,
            y2: entity.vertices[0].y,
          });
        } else {
          entities.push({
            type: "LINE",
            cut: true,
            x1: entity.vertices[i].x,
            y1: entity.vertices[i].y,
            x2: entity.vertices[i + 1].x,
            y2: entity.vertices[i + 1].y,
          });
        }
      }
    }
  });
  // return sort(entities);
  if (source === easyCeiling) {
    return easyCeilingCorrection(rotate(entities))
  }
  if (source === odeon) {
    return odeonCorrection(rotate(rotate(rotate(entities))));
  }
  return entities;
}

export function easyCeilingCorrection(entities: any) {
  // Проходим по всем точкам и ищем минимум, потому что эти дебилы пихают отрицательные точки
  let min_x = 0;
  let min_y = 0;
  entities.forEach((e: any) => {
    if (e.x1 < min_x) min_x = e.x1;
    if (e.x2 < min_x) min_x = e.x2;
    if (e.y1 < min_y) min_y = e.y1;
    if (e.y2 < min_y) min_y = e.y2;
  })

  // Делаем так чтобы все координаты были положительные
  // Еще делим на 10 потому что мы работаем в СМ, а они в ММ
  function ecCoordinateCorrection(c: number, min: number) {
    if (min <= 0) {
      return (c - min) / 10;
    }
  }

  return entities.map((e: any) => {
    if (e.type === "LINE") {
      return {
        type: "LINE",
        cut: true,
        x1: ecCoordinateCorrection(e.x1, min_x),
        y1: ecCoordinateCorrection(e.y1, min_y),
        x2: ecCoordinateCorrection(e.x2, min_x),
        y2: ecCoordinateCorrection(e.y2, min_y),
      }
    }
  })
}


export function odeonCorrection(entities: any) {

  entities.forEach((e: any) => {
    e.y1 = -e.y1;
    e.y2 = -e.y2;
  })
  // Проходим по всем точкам и ищем минимум, потому что эти дебилы пихают отрицательные точки
  let min_x = 0;
  let min_y = 0;
  entities.forEach((e: any) => {
    if (e.x1 < min_x) min_x = e.x1;
    if (e.x2 < min_x) min_x = e.x2;
    if (e.y1 < min_y) min_y = e.y1;
    if (e.y2 < min_y) min_y = e.y2;
  })

  // Делаем так чтобы все координаты были положительные
  function ecCoordinateCorrection(c: number, min: number) {
    if (min <= 0) {
      return (c - min);
    }
  }

  return entities.map((e: any) => {
    if (e.type === "LINE") {
      return {
        type: "LINE",
        cut: true,
        x1: ecCoordinateCorrection(e.x1, min_x),
        y1: ecCoordinateCorrection(e.y1, min_y),
        x2: ecCoordinateCorrection(e.x2, min_x),
        y2: ecCoordinateCorrection(e.y2, min_y),
      }
    }
  })
}


export function rotate(entities: Array<any>) {
  let newEntities: any = [];
  entities.map((entity: any) => {
    if (entity.type === "LINE") {
      newEntities.push({
        type: "LINE",
        cut: true,
        x1: entity.x1 * Math.cos(1.5708) - entity.y1 * Math.sin(1.5708),
        y1: entity.x1 * Math.sin(1.5708) - entity.y1 * Math.cos(1.5708),
        x2: entity.x2 * Math.cos(1.5708) - entity.y2 * Math.sin(1.5708),
        y2: entity.x2 * Math.sin(1.5708) - entity.y2 * Math.cos(1.5708),
      });
//       X = x * cos(alpha) — y * sin(alpha);
//       Y = x * sin(alpha) + y * cos(alpha);
    }
  });
  return newEntities;
}

export function reverse(entities: Array<any>) {
  return JSON.parse(JSON.stringify(entities)).reverse()
}

export function sort(entities: Array<any>) {
  console.log('entities:', entities)
  let buff = JSON.parse(JSON.stringify(entities));
  let sortEntities: any = [];

  // Находим минимум
  let e_min = buff[0];
  let e_min_i = 0;
  let l_min = l_between_points(e_min.x1, e_min.y1, 0, 0)
  buff.forEach((e: any, i: number) => {
    const l = l_between_points(e.x1, e.y1, 0, 0)
    const l2 = l_between_points(e.x2, e.y2, 0, 0)
    let e_buff = e;
    let L;
    if (l2 < l) {
      L = l2;
      e_buff = rotateEntity(e);
    } else {
      L = l;
    }
    if (L < l_min) {
      l_min = L;
      e_min = e_buff;
      e_min_i = i;
    }
  });
  sortEntities.push(e_min);
  buff.splice(e_min_i, 1);
  // console.log('after min search', 'buff:', buff, 'sort:', sortEntities);


  const findNearest = (last_e: any, e_arr: any) => {
    console.log('Начинаем поиск ближайшего для ')
    console.log(
      'type:', last_e.type,
      '\n x1:', last_e.x1.toFixed(2),
      'y1:', last_e.y1.toFixed(2),
      '\n x2:', last_e.x2.toFixed(2),
      'y2:', last_e.y2.toFixed(2),
    )
    let e_min_local_i = 0;
    let e_min_local = e_arr[0];

    // Если элемент всего один то и искать нечего
    if (e_arr.length === 1) {
      e_min_local_i = 0;
      e_min_local = e_arr[0];
      const l = l_between_points(last_e.x2, last_e.y2, e_min_local.x1, e_min_local.y1)
      const l2 = l_between_points(last_e.x2, last_e.y2, e_min_local.x2, e_min_local.y2)
      if (l2 < l) {
        e_min_local = rotateEntity(e_min_local);
      }
      return { e_min_local_i, e_min_local };
    }

    let l_min = l_between_points(last_e.x2, last_e.y2, e_arr[1].x1, e_arr[1].y1)
    e_arr.forEach((e: any, i: number) => {
      const l = l_between_points(last_e.x2, last_e.y2, e.x1, e.y1)
      const l2 = l_between_points(last_e.x2, last_e.y2, e.x2, e.y2)
      let L;
      let e_buff = e;
      console.log(
        'Вычисляем длины',
        '\n',
        'l:', l,
        '\n',
        'l2:', l2,
      )
      // Разворачиваем фигуру, если нужно
      if (l2 < l) {
        L = l2;
        e_buff = rotateEntity(e_buff);
      } else {
        L = l;
      }
      if (L < l_min) {
        console.log('Новый минимум!')
        e_min_local_i = i;
        l_min = L;
        e_min_local = e_buff;
      }
      console.log(
        'now_min:',
        '\n',
        e_min_local.x1.toFixed(2),
        e_min_local.y1.toFixed(2),
        '\n',
        e_min_local.x2.toFixed(2),
        e_min_local.y2.toFixed(2),
        '\n now_l_min:', l_min,
        '\n\n',
      );
    })
    return { e_min_local_i, e_min_local };
  }

  while (buff.length !== 0) {
    const last_e = sortEntities[sortEntities.length - 1];

    const find_e_i = buff.findIndex((e: any) => (equal_2_p(last_e.x2, e.x1) && equal_2_p(last_e.y2, e.y1)));
    if (find_e_i !== -1) {
      sortEntities.push(buff[find_e_i]);
      console.log('Подряд!',
        '\n last_e:', 'Тип:', last_e.type,
        '\nx1:', last_e.x1.toFixed(2),
        'y1:', last_e.y1.toFixed(2),
        '\nx2:', last_e.x2.toFixed(2),
        'y2:', last_e.y2.toFixed(2),
        '\n find_e', 'Тип:', buff[find_e_i].type,
        '\nx1:', buff[find_e_i].x1.toFixed(2),
        'y1:', buff[find_e_i].y1.toFixed(2),
        '\nx2:', buff[find_e_i].x2.toFixed(2),
        'y2:', buff[find_e_i].y2.toFixed(2),
      );
      buff.splice(find_e_i, 1);
    } else {
      const { e_min_local_i, e_min_local } = findNearest(last_e, buff);
      sortEntities.push(e_min_local);
      buff.splice(e_min_local_i, 1);
      console.log('Ближайшее!',
        '\n last_e:', 'Тип:', last_e.type,
        '\nx1:', last_e.x1.toFixed(2),
        'y1:', last_e.y1.toFixed(2),
        '\nx2:', last_e.x2.toFixed(2),
        'y2:', last_e.y2.toFixed(2),
        '\n find_e', 'Тип:', e_min_local.type,
        '\nx1:', e_min_local.x1.toFixed(2),
        'y1:', e_min_local.y1.toFixed(2),
        '\nx2:', e_min_local.x2.toFixed(2),
        'y2:', e_min_local.y2.toFixed(2),
      );
    }
  }

  console.log('e_sort:', sortEntities)
  return sortEntities;
}

export function rotateEntity(e: any) {
  //Функиця меняет направление реза фигуры
  let e_buff = e;
  if (e.type === "LINE") {
    e_buff = {
      type: e.type,
      cut: true,
      x1: e.x2,
      y1: e.y2,
      x2: e.x1,
      y2: e.y1,
    }
  }
  if (e.type === "ARC") {
    e_buff = {
      type: e.type,
      cut: true,
      x1: e.x2,
      y1: e.y2,
      x2: e.x1,
      y2: e.y1,
      radius: e.radius,
      fix_rotate: true, // Добавляем флаг чтобы использовать другую интерполяцию. Потому что дуга повернется зеркально, а нам нужно только поменять направление реза
    }
  }
  return e_buff;
}
